import './App.css';

function App() {
  return (
    <div className="gradient-background">
      <h1>Work in Progress!</h1>
      <p>Blog site is still under progress. You can still look at our works at</p>
      <a href="https://accelbia.design">Accelbia Design</a>
    </div>
  );
}

export default App;
